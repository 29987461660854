import { tns } from "tiny-slider/src/tiny-slider";

export default class MainSlider {
    static init() {
        const slider = document.getElementById('js-main-slider');
        const sliderControls = document.getElementById('js-main-slider-controls');
        const sliderList = document.getElementById('js-main-slider-list');

        if (slider) {
            let tinySlider = tns({
                container: slider,
                mode: 'gallery',
                autoplayButtonOutput: false,
                controls: true,
                nav: true,
                autoplay: true,
                loop: true,
                controlsContainer: sliderControls || false,
                navContainer: sliderList || false,
                items: 1,
                onInit: () => {
                    // set first three list items visible
                    for (let i = 0; i < sliderList.children.length; i++) {
                        sliderList.children[i].classList.add('visible');
                    }
                }
            });

            tinySlider.events.on('transitionStart', () => {
                let activeIndex = tinySlider.getInfo().displayIndex;
                activeIndex = activeIndex && activeIndex - 1

                // set visible items
                sliderList.children.forEach((child, i) => {
                    let diff = activeIndex === 0 || activeIndex === (sliderList.children.length - 1) ? 2 : 1

                    if (Math.abs(activeIndex - i) < (diff + 1) && !child.classList.contains('.visible')) {
                        child.classList.add('visible');
                    } else if (Math.abs(activeIndex - i) > 1) {
                        child.classList.remove('visible');
                    }
                })
            })
        }
    }
}
