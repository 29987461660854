import { tns } from "tiny-slider/src/tiny-slider";

export default class Slider {

    static setProductDetail() {
        const slider = document.getElementById('jsProductSlider');
        const prev = document.getElementById('jsProductSliderPrev');
        const next = document.getElementById('jsProductSliderNext');

        if (slider && prev && next) {
            let productSlider = tns({
                container: slider,
                mode: 'carousel',
                items: 1,
                slideBy: 'page',
                autoplayButtonOutput: false,
                prevButton: prev,
                nextButton: next,
                nav: false,
            });
        }
    }
}
